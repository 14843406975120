<script>
	import { SvelteToast } from '@zerodevx/svelte-toast';
	import { initializeStores } from '@skeletonlabs/skeleton';
	import '../app.postcss';
	let toastOptions = {
		theme: {
			'--toastBarHeight': 0
		}
	};
	initializeStores();
</script>

<SvelteToast options={toastOptions} />
<slot />

<style>
	@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
	html,
	body {
		@apply h-full overflow-hidden;
		box-sizing: border-box;
	}

	:root {
		--primary-khaki: #479F49;
        --primary-khaki2: #052C55;
		--primary-khaki-light: #e1e5e2;
		--primary-yellow: #fff2d1;
		--primary-beige: #e5e0da;
		--primary-white: #ffffff;
		--primary-charcoal: #D8D8D8;
		--primary-charcoal2: #3c3c3c;
		--gradient-khaki-dark: #468324;
		--gradient-khaki-light: #e1e5e2;
	}
</style>
